import React, { useEffect } from "react";
import {
  IoShieldCheckmark,
  IoFlash,
  IoConstruct,
  IoCheckmarkCircle,
  IoCloudDone,
  IoDocumentText,
} from "react-icons/io5";
import styled from "styled-components";
import { useModal } from "../components/useModal";

import { SignupForm } from "../components/forms";
import { Container, Wrapper, Row, Box, Button } from "../components/util";
import {
  CustomerQuote,
  Feature,
  ProductPricing,
  Modal,
} from "../components/site";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Image from "../components/image";

import PayHeroLogo from "../images/logos/PayHero/PayHero_AProductByFlexiTime.svg";
import PayHeroLogo_White from "../images/logos/PayHero/PayHero_Full_White.svg";
import PayHeroPlusASB from "../images/logos/Other/PayHeroPlusASB.svg";

const Or = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.colours.Secondary};
  padding: 0 10px;
  color: #ccc !important;
`;

const AsbPayHero = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  useEffect(() => {
    if (typeof document !== "undefined" && typeof window !== "undefined") {
      window.localStorage.setItem("utm_term", "ASBHUB");
    }
  }, []);

  return (
    <Layout>
      <Seo
        title="PayHero + ASB BusinessHub"
        description="PayHero has teamed up with ASB’s BusinessHub to support New Zealand businesses."
        pathname={props.location.pathname}
      />
      <Container bg="#fcbc1a">
        <Wrapper>
          <Row stackGap={100} alignCenter>
            <Box size={45} stackGap={40}>
              <Box stackGap={5}>
                <h1>
                  PayHero has teamed up with ASB’s BusinessHub to support New
                  Zealand businesses
                </h1>
                <h3 className="-fontNormal" css={{ color: "#333" }}>
                  Streamline your workforce management with PayHero payroll.
                </h3>
              </Box>
              <Button to="/asb#signup" className="-lg darkBlue">
                Get Payroll Now
              </Button>
            </Box>
            <Box size={55}>
              <img
                src={PayHeroPlusASB}
                className="-addShadow"
                alt="PayHero Payroll + ASB"
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Box stackGap={80}>
            <Box stackGap={50}>
              <img
                className="-center"
                height="70"
                src={PayHeroLogo}
                alt="PayHero Payroll"
              />
              <h2 className="-center -textCenter" css={{ maxWidth: "850px" }}>
                PayHero takes the stress out of payroll so you can focus on what
                matters most to your business.
              </h2>
            </Box>
            <Image
              filename="PayHeroHero_Full.png"
              alt="PayHero"
              maxWidth={850}
              centerImage
              addShadow
            />
            <CustomerQuote
              quote="The only software on the market currently able to accurately calculate the leave entitlements for employees with irregular working patterns, without manual intervention, is PayHero."
              name="Irene Bennetts"
              company="Admin Army"
              pic="Irene_Circle.png"
              large
              center
            />
          </Box>
          <hr />
          <Box stackGap={80}>
            <Row stackGap={80}>
              <Box size={50}>
                <Feature
                  icon={<IoShieldCheckmark key={0} />}
                  title="Payroll compliance made easy"
                  description="Relax safe in the knowledge that your payroll system follows the very latest Holidays Act guidance from MBIE and legislative updates are automatically applied."
                />
              </Box>
              <Box size={50}>
                <Feature
                  icon={<IoFlash key={0} />}
                  title="Fast, easy, accurate & stress-free"
                  description="Pay your staff in minutes and let our powerful online payroll software do the hard work for you. Take care of all your wage and leave calculations, payslips and payday filing in a few clicks."
                />
              </Box>
            </Row>
            <Row stackGap={80}>
              <Box size={50}>
                <Feature
                  icon={<IoConstruct key={0} />}
                  title="Enjoy supercharged payroll tools"
                  description="PayHero is easy to set up, with a team of payroll experts on hand to help answer any questions you may have. Employee documents can be securely stored online and custom reports can be generated at any time."
                />
              </Box>
              <Box size={50}>
                <Feature
                  icon={<IoCheckmarkCircle key={0} />}
                  title="Payroll your employees can trust"
                  description="Annual leave and holiday laws are complex, but we’ll make it easier to understand your obligations and for your staff to trust the entitlements and deductions in their pay."
                />
              </Box>
            </Row>
            <Row stackGap={80}>
              <Box size={50}>
                <Feature
                  icon={<IoCloudDone key={0} />}
                  title="Experience a powerful cloud system"
                  description="Pay your wages from any computer or tablet with an internet connection, and rest easy knowing your sensitive data is kept safe with serious security measures."
                />
              </Box>
              <Box size={50}>
                <Feature
                  icon={<IoDocumentText key={0} />}
                  title="Payday Filling, sorted once and for all"
                  description="PayHero makes payday filling easy - just connect your account to IRD and we’ll automatically send the details through after every pay."
                />
              </Box>
            </Row>
          </Box>
          <hr />
          <Box stackGap={60}>
            <div className="-textCenter">
              <h2>PayHero Pricing</h2>
              <h4>No Hidden Costs · Easy Set Up · 14 Day Free Trial</h4>
            </div>
            <ProductPricing
              selectedProduct="payhero"
              ctaLink="/asb#signup"
              hideAddOns
            />
            <p className="-textCenter" css={{ color: "#999" }}>
              Prices are in NZD and exclude GST.
            </p>
          </Box>
        </Wrapper>
      </Container>
      <Container className="secondary" id="signup">
        <Wrapper stackGap={80} maxWidth={800}>
          <Box stackGap={60}>
            <Box stackGap={30} className="-textCenter">
              <img
                className="-center"
                height="30"
                src={PayHeroLogo_White}
                alt="PayHero Payroll"
              />
              <Box stackGap={10}>
                <h2 className="-textCenter">
                  Join thousands of other Kiwis using PayHero today and receive
                  your first two months free
                </h2>
                <h4>
                  Streamline your workforce management with PayHero payroll. ASB
                  customers get the first two months of their PayHero
                  subscription for free. Simply use promo code{" "}
                  <span
                    css={{
                      padding: "1px 4px",
                      border: "solid 1px #ddd",
                      borderRadius: "5px",
                      fontWeight: "500",
                    }}
                  >
                    ASBHUB
                  </span>{" "}
                  when subscribing to PayHero.
                </h4>
              </Box>
            </Box>
            <Box className="-center" stackGap={40} css={{ maxWidth: "520px" }}>
              <SignupForm hideFormExtras center />
              <Box>
                <hr css={{ background: "#ddd" }} />
                <Or>Or</Or>
              </Box>
              <Button className="dark -lg -full" onClick={toggleModal} atag>
                Book a Sales Call
              </Button>
              <p
                className="-textCenter"
                css={{ opacity: "0.6", marginTop: "30px !important" }}
              >
                * This promotional offer is available for ASB Business customers
                creating a new subscription to PayHero, and can’t be used with
                any other offer on the same subscription. The two month free
                period begins on the date the subscription is purchased and is
                available for up to a maximum of $100 per month.
              </p>
            </Box>
          </Box>
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default AsbPayHero;
